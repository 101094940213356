<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-select
            :options="$LIST_JENJANG"
            v-model="selJenjang"
            outlined
            @input="getListGuru()"
            label="Jenjang"
            style="min-width: 130px;"
          ></q-select>
          <q-btn
            icon="add"
            color="primary"
            label="tambah guru"
            :to="'/akademikeditguru/-1/' + selJenjang"
          ></q-btn>
        </div>

        <q-input outlined label="Cari GURU/NIK" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nik</th>
            <th>nama</th>
            <th style="width:20px;">guru mapel</th>
            <th style="width:20px;">guru bk</th>
            <th style="width:20px;">wali kelas</th>
            <th style="width:20px;">pembina ekskul</th>
            <th style="width:20px;">kurikulum</th>
            <th style="width:20px;">kepala sekolah</th>
            <th style="width:20px;">super admin</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filGuru" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nik }}</td>
            <td>{{ val.nama }}</td>
            <td>
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_guru_mapel"
              ></q-checkbox>
            </td>
            <td>
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_guru_bk"
              ></q-checkbox>
            </td>
            <td>
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_wali_kelas"
              ></q-checkbox>
            </td>
            <td>
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_pembina_ekskul"
              ></q-checkbox>
            </td>
            <td>
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_kurikulum"
              ></q-checkbox>
            </td>
            <td>
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_kepala_sekolah"
              ></q-checkbox>
            </td>
            <td>
              <q-checkbox
                disable
                true-value="1"
                false-value="0"
                v-model="val.is_super_user"
              ></q-checkbox>
            </td>
            <td class="q-gutter-sm">
              <q-btn
                flat
                dense
                icon="edit"
                color="secondary"
                :to="'/akademikeditguru/' + val.id + '/' + selJenjang"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-section align="right">
        <q-btn outline color="primary" @click="exportExcel">export excel</q-btn>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      selJenjang: "",
      listGuru: [],
      searchTerm: "",
    };
  },
  computed: {
    filGuru() {
      if (this.searchTerm == "") return this.listGuru;
      else {
        return this.listGuru.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNIK = val.nik.indexOf(this.searchTerm) != -1;
          return cNama || cNIK;
        });
      }
    },
  },
  async mounted() {
    this.selJenjang = this.$route.params.jenjang;
    await this.getListGuru();
  },
  methods: {
    async exportExcel() {
      let temp = this.listGuru.slice(0);
      temp.forEach((el) => {
        delete el.password;
      });
      var ws = this.$xlsx.utils.json_to_sheet(temp);
      var wb = this.$xlsx.utils.book_new();
      this.$xlsx.utils.book_append_sheet(wb, ws, `GURU ${this.selJenjang}`);

      var now = moment().format("DD MMM YYYY");
      this.$xlsx.writeFile(wb, `data guru ${now}.xlsx`);
    },
    async getListGuru() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/akademik/guru/list/" + this.selJenjang, this.$hlp.getHeader())
        .then((resp) => {
          this.listGuru = resp.data;
        });
      if (this.selJenjang != this.$route.params.jenjang)
        this.$router.replace({ path: "/akademiklistguru/" + this.selJenjang });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
